<template>
    <PageLayout>
        <PageTitle slot="top" :title="title"/>

        <div class="body">
            <router-link v-for="item in team" :key="item.url" :to="item.url" tag="div" class="entry">
                <div class="photo">
                    <div class="name">{{ item.name }}</div>
                    <div class="border"><img :src="item.image"></div>
                </div>
                <div class="title">{{ item.title }}</div>
            </router-link>
        </div>
    </PageLayout>
</template>

<script>
    import Vue from 'vue';
    import PageLayout from '../components/PageLayout';
    import PageTitle from '../components/PageTitle';

    export default Vue.extend({
        name: 'OurTeam',
        components: {PageLayout, PageTitle},
        data: () => ({
            title: 'Our Team'
        }),
        created() {
            const team = require('../data/team');

            this.team = team.sort((a, b) => a.order < b.order ? -1 : 1);
        }
    });
</script>

<style scoped lang="stylus">
    @require '../variables.styl';

    .body {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;
        align-items: stretch;

        .entry {
            display: grid;
            flex-shrink: 1;
            max-width: 256px;
            margin: 0 20px 40px 0;
            grid-template-rows: 256px 1fr;
            justify-items: center;
            cursor: pointer;

            .photo {
                grid-row: 1;
                grid-column: 1;
                padding: 0 0 0 0;
                position: relative;
                background-color: text-color;

                .name {
                    grid-row: 1;
                    grid-column: 1;
                    title_font();
                    font-size: 24px;
                    color: white;
                    position: absolute;
                    left: 0;
                    top: 190px;
                    padding: 6px 12px;
                    background-color: rgba(black, 0.7);
                }

                .border {
                    border: solid 3px text-color;
                    width: 250px;
                    height: 250px;

                    img {
                        display: block;
                    }
                }
            }

            .title {
                grid-row: 2;
                grid-column: 1;
                font-size: 14px;
                padding-top: 8px;
                text-align: center;
            }
        }
    }
</style>
