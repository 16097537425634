<template>
    <nav v-once>
        <NavigationButton v-for="link in links"
            :key="link.title"
            :title="link.title"
            :url="link.url"
            :links="link.links"/>
    </nav>
</template>

<script>
    import Vue from 'vue';
    import NavigationButton from './NavigationButton';

    export default Vue.extend({
        name: 'Navigation',
        components: {NavigationButton},
        props: {
            links: {
                type: Array,
                required: true
            }
        }
    });
</script>

<style scoped lang="stylus">
    @require '../variables.styl';

    nav {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        flex-wrap: nowrap;
    }

    @media small-query {
        nav {
            justify-content: center;
            flex-wrap: wrap;
        }
    }
</style>
