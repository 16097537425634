<template>
    <div id="layout">
        <div id="top"><slot name="top"/></div>
        <div id="page"><slot/></div>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        name: 'PageLayout'
    });
</script>

<style lang="stylus" scoped>
    @require '../variables.styl';

    #layout {
        display: grid;
        grid-template-columns: 1fr minmax(min-page-width, max-page-width) 1fr;
        grid-template-areas: "top top top" \
            " . page .";

        #top {
            grid-area: top;
        }

        #page {
            grid-area: page;
            margin: 40px 10px;
        }

        @media small-query {
            #page {
                margin: 10px 10px;
            }
        }
    }
</style>
