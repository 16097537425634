module.exports = {
    jobs: [
        {
            title: 'Mechanical Engineer',
            sections: [
                {
                    heading: 'Job Description:',
                    text: 'Lead the technical development and economic evaluation of various solutions. Design and guide the fabrication of process equipment from concept, testing and production using Solidworks, Autocad and other 3D modeling software. Prepare analyses of piping and ASME pressure vessels and coordinate with subcontractors, when required, for assessing equipment performance and compliance with industry standards using Compress pressure vessel design software. Design, size, and when required, procure different types of process equipment from vendors, which requires understanding of equipment such as pumps, compressors, valves, sensors and controls. Develop innovative and creative design solutions using Solidworks that result in a competitive advantage for the company including process improvement and cost reduction. Design process equipment from concepts to detailed specifications through the use of Solidworks and ASME Code. Assist in the design, fabrication and certification process of gas processing equipment with the use of Compress pressure vessel design software and ASME Code for certification process. Assist with performance testing and analysis of the product with ASME. Must work with multiple departments to ensure cost effective solutions and support the fabrication process.'
                },
                {
                    heading: 'Minimum requirements:',
                    text: 'Bachelors Degree in Mechanical Engineering, Electromechanical Engineering, or equivalent and 18 months experience reading and detailing piping instrumentation diagrams as well as the design, preliminary cost estimation and fabrication of ASME pressure vessels and modular skid packages for gas processing equipment is required.'
                },
                {
                    heading: 'Apply:',
                    text: 'Qualified candidates may email resume to: <a href="mailto:jobs@dewalch.com">jobs@dewalch.com</a>'
                }
            ]
        }
    ]
};

