module.exports = () => ({
    'Utility Security': [
        {
            name: 'Meter Locking Rings',
            url: '/meter-locking-rings',
            products: [
                {
                    title: 'Swift Ring™ Stainless Steel',
                    url: '/swift-ring',
                    description: 'The Swift Ring™ is a preloaded, low-cost stainless steel meter locking ring. This ring is a one-piece device that allows easy and quick installation, even in limited spaces. Its aluminum seal is bar -coded and protected by a lens to increase the security and reliability of the ring. The Swift Ring™ is manufactured from stainless steel for corrosion resistance.',
                    sections: [
                        {
                            name: 'Features and Benefits',
                            features: [
                                'Low-cost',
                                'Tamper evident – seal must be broken to open the ring',
                                'Quick and easy installation – pre-loaded, no tools required',
                                'Ideal for limited spaces',
                                'Ring is reusable after the seal has been broken',
                                'Seal replacement available'
                            ]
                        }
                    ]
                },
                {
                    title: 'Rapid Ring™ Stainless Steel',
                    url: '/rapid-ring',
                    description: 'The Rapid Ring™ is a low cost, low security stainless steel meter locking ring. This ring is a one piece device designed to allow easy and quick installation. Its integrated housing and thumbscrew prevent losing parts and allow installation without tools. The Rapid Ring™ is manufactured from stainless steel for corrosion resistance.',
                    sections: [
                        {
                            name: 'Features and Benefits',
                            features: [
                                'Low-cost',
                                'Manufactured from stainless steel for corrosion resistance',
                                'Thumbscrew and housing integrated to ring, which also prevents parts from getting lost',
                                'Housing rotates to allow easier installation of seal',
                                'No tools required: screwdriver or hex wrench may be used if needed'
                            ]
                        }
                    ]
                },
                {
                    title: 'Upgradeable Ring™ Stainless Steel',
                    url: '/upgradeable-ring',
                    description: 'The Upgradeable Ring™ is a multifunctional, low-cost, stainless steel meter locking ring. It provides a basic level of security with the option to be upgraded to a higher level at any time. The Upgradeable Ring™ incorporates a thumbscrew to adjust to different meter sizes, with a lock receiver to insert a lock if needed. This ring is manufactured from 300 series stainless steel with no dissimilar metals for outstanding corrosion resistance.',
                    sections: [
                        {
                            name: 'Features and Benefits',
                            features: [
                                'Low-cost',
                                'Flexibility to upgrade to a higher level of security',
                                'Thumbscrew and receiver integrated to ring, which prevents parts from getting lost',
                                'Similar metals used to prevent galvanic corrosion and increase product lifespan',
                                'Quick and easy installation – secured with two turns of thumbscrew, no tools required'
                            ]
                        }
                    ]
                },
                {
                    title: 'EZ Ring™ Stainless Steel',
                    url: '/ez-ring',
                    description: 'The EZ Ring™ is a low-cost, stainless steel meter locking ring. This ring is a one-piece device designed to allow easy and quick installation. The EZ Ring™ is manufactured from 300 series stainless steel with no dissimilar metals, making it the perfect choice for highly corrosive environments. Its lock-retained option eliminates the need to carry a separate piece to install. This product is also available in carbon steel.',
                    sections: [
                        {
                            name: 'Features and Benefits',
                            features: [
                                'Similar metals used to prevent galvanic corrosion and increase product lifespan',
                                'Low-cost',
                                'Preload option for keyless installation',
                                'Lock retained feature prevents the lock from getting lost',
                                'Available with standard, short-length plunger or disk-tumbler-barrel lock'
                            ]
                        }
                    ]
                },
                {
                    title: 'Sentinel 34™ Stainless Steel',
                    url: '/sentinel-34',
                    description: 'The Sentinel 34™ is a stainless steel, one piece design meter locking ring. This ring is manufactured from 300 series stainless steel with no dissimilar metals, making it the perfect choice for highly corrosive environments. Its serrated seam and interlocking system prevent insertion of prying tools that may compromise the integrity of the meter and ring. Its front-entry design allows utility personnel to quickly install and remove the lock with no hassle.',
                    sections: [
                        {
                            name: 'Features and Benefits',
                            features: [
                                'Similar metals used to prevent galvanic corrosion and increase product lifespan',
                                'Preload option for keyless installation',
                                'Lock flush option for high security',
                                'Lock retained feature prevents the lock from getting lost',
                                'User friendly - front entry and one-piece design',
                                'Available with standard, short-length plunger or disk-tumbler-barrel lock',
                                'Available with anti-rotation feature',
                                'Available with permanent and non-permanent lock retained, as well as non-lock retained'
                            ]
                        }
                    ]
                },
                {
                    title: 'Sentinel 45™ Hardened',
                    url: '/sentinel-45',
                    description: 'The Sentinel 45™ secures electric meters from multiple types of tampering. This high-security-meter-locking ring is manufactured from heavy duty carbon steel and hardened for superior cut resistance. Its serrated seam and interlocking system prevent insertion of prying tools that may compromise the integrity of the meter and ring. Its front entry design allows utility personnel to quickly install and remove the lock with no hassle.',
                    sections: [
                        {
                            name: 'Features and Benefits',
                            features: [
                                'Case hardened to withstand aggressive tampering',
                                'Similar metals used to prevent galvanic corrosion and increase product lifespan',
                                'Corrosion resistant: Plated for superior resistance',
                                'Preload option for keyless installation',
                                'Lock retained feature prevents the lock from getting lost',
                                'User friendly - front entry and one piece design',
                                'Available with standard, short length plunger or disk tumbler barrel lock',
                                'Available with anti-rotation feature',
                                'Available with permanent and non-permanent lock retained, as well as non-lock retained'
                            ]
                        }
                    ]
                },
                {
                    title: 'Sentinel 50™ Hardened',
                    url: '/sentinel-50',
                    description: 'The Sentinel 50™ is a front entry meter locking ring that provides high security and adapts to various meter sizes. This high-security-meter-locking ring is manufactured from heavy duty carbon steel and hardened for superior cut resistance. Its housing is armored to protect the ring and lock from attacks.',
                    sections: [
                        {
                            name: 'Features and Benefits',
                            features: [
                                'Case hardened to withstand aggressive tampering',
                                'Hardened housing increases security and lock reliability',
                                'Similar metals used to prevent galvanic corrosion and increase product lifespan',
                                'Corrosion resistant: Plated for superior resistance',
                                'User friendly - front entry design allows right or left handed installation',
                                'Screw-tightening design adjusts to the size of the meter and provides maximum security',
                                'Available with anti-rotation feature',
                                'Available with standard or short length barrel lock'
                            ]
                        }
                    ]
                }
            ]
        },
        {
            name: 'Ringless Meter Locking Devices',
            url: '/ringless-meter-locking-devices',
            products: [
                {
                    title: 'Armadillo™ - Side/Bottom Mount',
                    url: '/armadillo',
                    description: 'The Armadillo™ is one of the most secure enclosure locks in the market. This lock is engineered to secure ringless meter sockets from either side or bottom. It is manufactured from carbon steel and hardened to provide superior security and cut resistance. The Armadillo™ is bolted down to the meter socket for tighter fitting and increased security.  The height of its clamping arm is adjustable to fit various meter socket sizes.',
                    sections: [
                        {
                            name: 'Features and Benefits',
                            features: [
                                'Our exclusive Swing-away System allows utility personnel to open the meter socket without removing the enclosure lock',
                                'Manufactured from carbon steel and hardened to withstand aggressive tampering',
                                'Plated for superior corrosion resistance',
                                'Security tabs discourage cutting between box and body',
                                'Snap Lock™ feature available for keyless installation',
                                'Available in different clamping arm lengths and thicknesses',
                                'Available with anti-rotation feature',
                                'Available with standard, short length plunger or disk tumbler barrel lock'
                            ]
                        }
                    ]
                },
                {
                    title: 'Liplock™-Top Mount',
                    url: '/liplock',
                    description: 'The Liplock™ is an innovative locking device offered exclusively by DeWalch. This lock secures the top of the meter socket and prevents prying on its lip. Its cams tighten the device and prevent sliding for added security. The Liplock™ is made of carbon steel and hardened for superior cutting resistance.',
                    sections: [
                        {
                            name: 'Features and Benefits',
                            features: [
                                'Low cost',
                                'Unique, low profile locking device',
                                'Manufactured from carbon steel and hardened to withstand aggressive tampering',
                                'User friendly, allows quick and easy installation',
                                'Outstanding complement to enclosure locks'
                            ]
                        }
                    ]
                },
                {
                    title: 'Prolock™ - Bottom Mount',
                    url: '/prolock-bottom',
                    description: 'The Prolock™ is an enclosure lock that is engineered to secure ringless meter sockets from unauthorized access. No tools are needed to install this locking device, thus, eliminating the need to punch a hole and damage the meter socket. The Prolock™ bottom can be installed on either side of the meter box. Its body is made from carbon steel and hardened to provide superior security and cut resistance. Its compact size, quick and easy installation, makes it the ideal choice for gang meter sockets and AMI projects.',
                    sections: [
                        {
                            name: 'Features and Benefits',
                            features: [
                                'Compact size',
                                'Quickest installation: installs in seconds without tools or screws to manipulate',
                                'Manufactured from carbon steel and hardened to withstand aggressive tampering',
                                'Plated for superior corrosion resistance',
                                'Latch design prevents the lid from closing unless latch is 100% engaged',
                                'Security tabs discourage cutting between box and body',
                                'Snap Lock™ feature available for keyless installation',
                                'Available with anti-rotation feature',
                                'Available with standard length plunger or disk tumbler barrel lock'
                            ]
                        }
                    ]
                },
                {
                    title: 'Prolock™ - Side Mount',
                    url: '/prolock-side',
                    description: 'The Prolock™ is an enclosure lock that is engineered to secure ringless meter sockets from unauthorized access. No tools are needed to install this locking device, thus, eliminating the need to punch a hole and damage the meter socket. The Prolock™side can be installed on either side of the meter box. Its body is made from carbon steel and hardened to provide superior security and cut resistance. Its compact size, quick and easy installation, makes it the ideal choice for AMI projects.',
                    sections: [
                        {
                            name: 'Features and Benefits',
                            features: [
                                'Compact size',
                                'Fast installation: installs in seconds, no tools needed',
                                'Manufactured from carbon steel and hardened to withstand aggressive tampering',
                                'Plated for superior corrosion resistance',
                                'Hardened teeth prevent body from sliding when being attacked',
                                'Security tabs discourage cutting between box and body',
                                'Snap Lock™ feature available for keyless installation',
                                `Special screw designed for ease of use and added security:<ul>
                        <li>Round and compact design prevents external loosening
                        <li>Allows use of a T-Handle Hex Key</li></ul>`,
                                'Available with standard length plunger or disk tumbler barrel lock',
                                'Available with anti-rotation feature',
                                'Available with Insulated T-Handle Hex Key to tighten screw for added security'
                            ]
                        }
                    ]
                },
                {
                    title: 'Tamper Resistant Screw',
                    url: '/tamper-resistant-screw',
                    description: 'The tamper resistant screw is a low cost, one-time-use security product. The screw includes a self drilling mechanism that eliminates the need for a hole puncher. A standard drill is all that is needed for installation; once the screw is installed the screw head comes off. The tamper resistance screw can be used in a variety of applications including ringless meter sockets. The screw is a very secure and inexpensive way to secure gang meter sockets.',
                    sections: [
                        {
                            name: 'Features and Benefits',
                            features: [
                                'Low cost',
                                'Hardened screw and washer for superior cut resistance',
                                'Plated for superior corrosion resistance',
                                'Washer prevents screw from being exposed',
                                'User friendly – self-drilling mechanism eliminates the need to punch a hole'
                            ]
                        }
                    ]
                },
                {
                    title: 'S-Hook™',
                    url: '/s-hook',
                    description: 'The S-Hook™ is a low-cost locking device engineered to secure ringless meter sockets. This lock is made of carbon steel and hardened for higher security and superior cut resistance. The design allows for easy and quick installation on the meter socket sealing tab.',
                    sections: [
                        {
                            name: 'Features and Benefits',
                            features: [
                                'Low cost',
                                'Manufactured from carbon steel and hardened to withstand aggressive tampering',
                                'Compact design',
                                'User friendly – quick and easy installation',
                                'Snap Lock™ feature available for keyless installation',
                                'Available with standard length plunger or disk tumbler barrel lock'
                            ]
                        }
                    ]
                }
            ]
        },
        {
            name: 'Barrel Lock and Keys',
            url: '/barrel-lock-and-keys',
            products: [
                {
                    title: 'eLoc™ System',
                    url: '/eloc',
                    description: 'After years of research and development, DeWalch introduces the Electronic System or e-Loc™. This system offers key control and addresses vulnerabilities of existing locking systems. The DeWalch e-Loc™ is a comprehensive system that offers a web based management tool to control keys and access records of user activity.',
                    sections: [
                        {
                            name: 'Features of Key',
                            features: [
                                'Compact size allowing convenient carrying',
                                'Impact resistant',
                                'Lightweight',
                                'Easy to operate',
                                'Long battery life, approximately 1000 openings',
                                'Configurable authorization',
                                'Visual feedback on key',
                                'Exclusive codes for each utility',
                                'Tangible event data recording and reporting',
                                'Bluetooth® Smart',
                                'Key charger included'
                            ]
                        },
                        {
                            name: 'Features of Lock',
                            features: [
                                'Cannot be re-programmed for added security',
                                'Exclusive codes for each utility',
                                `Lock holds information on:<ul>
                                <li>Customer account</li>
                                <li>Serial number</li>
                                <li>Group number</li>
                                <li>Holds up to 8 audit trail events</li></ul>`,
                                'Lock offers superior reliability',
                                'Lock will not open unless key has been authorized',
                                'Resistant to attack with magnets, bumping, electrocution, and picking',
                                'Fits existing hardware',
                                'Short and standard lengths available',
                                'Stainless steel for excellent corrosion resistance',
                                'No keyhole',
                                'Keeps dirt, sand, and bugs out',
                                'Prevents objects from being inserted',
                                'Improves tamper resistance',
                                'No power or battery required',
                                'Lock is sealed to keep water out of mechanism',
                                'Contact pins provide secure and reliable communication'
                            ]
                        },
                        {
                            name: 'Web Management Interface',
                            features: [
                                'Bluetooth® Smart enables users to authorize any DeWalch e-loc key using an app on an Android smart phone or tablet',
                                'Manage users',
                                'Manage locks',
                                'Review reports',
                                'Key and lock control'
                            ]
                        }
                    ]
                },
                {
                    title: 'Rotor™ Barrel Lock and Key System',
                    url: '/rotor-lock',
                    description: 'The Rotor™ Barrel Lock and Key System is a unique and innovative locking system offered exclusively by DeWalch. This system was introduced as a response to the need for a more secure and reliable system in the market. The Rotor™ system offers utilities superior security and reliability features.',
                    sections: [
                        {
                            name: 'Features of Key',
                            features: [
                                'The RotorKey™ contains moving parts for added security',
                                'The RotorKey™ cannot be duplicated by molding and using simple hand tools',
                                'Made of high strength stainless steel',
                                'Convenient size allowing to fit on key ring',
                                'Patented Anti-Rotation Key offers easy and convenient removal of the barrel lock using one hand',
                                'Engraved serial numbers for tracking purposes and added security',
                                'Lifetime warranty'
                            ]
                        },
                        {
                            name: 'Features of Lock',
                            features: [
                                'Anti-pick notches on tumblers and anti-drill disk on lock for excellent tamper resistance',
                                'Factory controlled key and lock codes',
                                'Multi generation and code exclusivity available',
                                'Exclusive Snap Lock™ technology available for permanent, ultra-fast keyless installation',
                                'Patented disk tumbler lock for superior reliability in various climates',
                                'Stainless steel available for superior corrosion resistance',
                                'Carbon steel locks are hardened for cut resistance and plated for corrosion resistance',
                                'Standard and short length options',
                                'Weather seal available to prevent entry of dirt and debris',
                                'Anti-rotation feature available'
                            ]
                        }
                    ]
                },
                {
                    title: 'Plunger Style Barrel Locks and Keys',
                    url: '/plunger-lock',
                    description: 'DeWalch’s plunger lock and key system offers security and proven durability. This system integrates innovative security and durability features to provide utility companies with the highest level of security and quality. DeWalch’s plunger system is available in a variety of mechanical codes and is compatible with most manufacturers’ systems.',
                    sections: [
                        {
                            name: 'Features and Benefits',
                            features: [
                                'Low cost',
                                'Exclusive Snap Lock™ technology available for permanent, ultra-fast keyless installation',
                                'Multiple security level options',
                                'Stainless steel available for superior corrosion resistance',
                                'Carbon steel locks are hardened for cut resistance and plated for corrosion resistance.',
                                'Standard and short length options',
                                'Weather seal available to prevent entry of dirt and debris',
                                'Keys include engraved serial numbers for tracking and added security'
                            ]
                        }
                    ]
                },
                {
                    title: 'DT 1000™ System',
                    url: '/dt-1000',
                    description: 'The DT 1000™ Barrel Lock and Key System is one of DeWalch’s latest responses to the security needs in the market. This system provides utilities with enhanced meter security and ease of use. The DT 1000™ system integrates state-of-the-art features for outstanding tamper resistance.',
                    sections: [
                        {
                            name: 'Features and Benefits of Lock',
                            features: [
                                'Anti-pick notches on tumblers',
                                'End cap resists drilling and prying',
                                'Factory controlled key and lock codes',
                                'Multi generation combinations available',
                                'Exclusive combinations available',
                                'Exclusive Snap Lock™ technology available for unlimited, ultra-fast keyless installations',
                                'Superior reliability in various climates',
                                'Stainless and hardened carbon steel options',
                                'Standard and short length options',
                                'Internal weather seal available to prevent entry of dirt and debris',
                                'Anti-rotation feature available'
                            ]
                        },
                        {
                            name: 'Features and Benefits of Key',
                            features: [
                                'Key duplication: The DT 1000™ key incorporates a patented keyway geometry that prevents a counterfeiter from making a key from a simple blank such as a nail or a piece of sheet metal',
                                'Anti-rotation Key: patented anti-rotation key offers easy lock removal with one hand; it is designed to prevent the lock from rotating where the user is unable to grasp the lock',
                                'Master keying and multiple generation coding',
                                'Exclusive codes',
                                'Convenient size allowing it to fit on key ring',
                                'Made of superior strength stainless steel',
                                'Includes 10 year warranty',
                                'Engraved serial numbers for tracking purposes and added security'
                            ]
                        }
                    ]
                }
            ]
        },
        {
            name: 'Tools and Accessories',
            url: '/tools-and-accessories',
            products: [
                {
                    title: 'Ferrules, Sealing Cap and Weather Caps',
                    url: '/ferrules-caps',
                    description: 'DeWalch offers a wide variety of accessories designed to protect and enhance the service of barrel locks. The caps extend the lifespan of barrel locks by protecting them from severe weather exposure and foreign matter. The ferrule is a replaceable, tamper evident accessory used to seal locking devices. All accessories are manufactured using the highest quality of materials in the market.',
                    sections: [
                        {
                            name: 'Features and Benefits',
                            features: [
                                'Extend the barrel locks operating lifespan',
                                'Compatible with most manufacturers’ barrel locks',
                                'Protect barrel locks from severe weather conditions and foreign matter',
                                'Added tamper evidence',
                                'Available in metal and plastic material'
                            ]
                        }
                    ]
                },
                {
                    title: 'Barrel Lock Cleaning Tool',
                    url: '/cleaning-tools',
                    description: 'The new DeWalch barrel lock cleaning tool is a convenient device designed to remove dirt, debris, insect nests and rust from barrel locks. This tool works smoothly with most manufacturers’ barrel locks. This cleaning device is made of aluminum and incorporates a 2-in-1feature. The firm 1/8” drill bit loosens and removes tough debris while the stainless steel wire brush cleans out the loosened debris and dirt. The DeWalch barrel lock cleaning tool extends the barrel locks operating life by enhancing lock functionality.',
                    sections: [
                        {
                            name: 'Features and Benefits',
                            features: [
                                'Easy to use',
                                'Fits in your pocket',
                                'Compatible with most manufacturers’ barrel locks',
                                'Made of lightweight aluminum',
                                'Cleans barrel locks extensively',
                                'Extends the barrel locks operating lifespan'
                            ]
                        }
                    ]
                },
                {
                    title: 'Universal Lock Puller',
                    url: '/lock-puller',
                    description: 'The Universal Lock Puller is an excellent solution to remove damaged locks. This tool extracts any lock that cannot be opened with a key. DeWalch’s Universal Lock Puller pulls most manufacturers’ plunger barrel locks and it is the only tool that works with DeWalch flushed disk tumbler locks. The lock puller is made of high strength steel alloy for high durability.',
                    sections: [
                        {
                            name: 'Features and Benefits',
                            features: [
                                'Compatible with most manufacturers’ plunger barrel locks',
                                'Only lock puller that works with DeWalch flushed disk tumbler locks',
                                'High reliability – field proven',
                                'High durability – made of high strength steel alloy',
                                'Parts are replaceable to minimize cost'
                            ]
                        }
                    ]
                }
            ]
        },
        {
            name: 'Gas and Water Utilities',
            url: '/gas-and-water',
            products: [
                {
                    title: 'Barrel Locks and End Caps',
                    url: '/barrel-locks-end-caps',
                    description: 'The DeWalch barrel lock with end cap is a secure and inexpensive system designed to protect wing style gas and water valves. The end caps can be used with plunger or disk tumbler style barrel locks. This system allows utilities to complete installation fast and easy. Barrel locks and end caps are available in stainless steel or hardened carbon steel.',
                    sections: [
                        {
                            name: 'Features and Benefits',
                            features: [
                                'Low cost',
                                'Fit wing style gas and water valves',
                                'Hardened for superior cut resistance',
                                'Plated for superior corrosion resistance',
                                'Engineered to withstand tampering with prying tools',
                                'Exclusive Snap Lock™ feature available for keyless installation',
                                'Available in standard and short length',
                                'Available in stainless steel and carbon steel'
                            ]
                        }
                    ]
                }
            ]
        },
        {
            name: 'General Purpose Padlocks',
            url: '/padlocks',
            products: [
                {
                    title: 'DeWalch Padlock',
                    url: '/padlock',
                    description: 'DeWalch’s padlocks are designed to provide security solutions for utilities. This locking device can be used with any applications that can be secured with a padlock. The padlocks are ideal are ideal for securing metering accessories, CT cabinets as well as substation locations. Our padlocks are compatible with plunger and disk tumbler barrel locks which allow utilities to use their existing system and exclusive code.',
                    sections: [
                        {
                            name: 'Features and Benefits',
                            features: [
                                'Manufactured from carbon steel and hardened to withstand aggressive tampering',
                                'Shackle hardened to resist bolt cutters and saws',
                                'Similar metals used to prevent galvanic corrosion and increase product lifespan.',
                                'Plated for superior corrosion resistance',
                                'Snap Lock™ feature available for keyless installation',
                                'User friendly – compact size',
                                'Available in several shackle lengths and configurations',
                                'Available with standard, short length plunger or disk tumbler barrel lock'
                            ]
                        }
                    ]
                }
            ]
        }
    ]
});
