<template>
    <div class="header">
        <div class="inner">
            <router-link :to="'/'" tag="div" class="logo"><Logo/></router-link>
            <Navigation :links="links"/>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Logo from '../assets/logo.svg';
    import Navigation from './Navigation';

    export default Vue.extend({
        name: 'Header',
        components: {Navigation, Logo},
        created() {
            this.links = require('../data/nav');
        }
    });
</script>

<style scoped lang="stylus">
    @require '../variables.styl';

    .header {
        display: grid;
        grid-template-columns: 1fr minmax(min-page-width, max-page-width) 1fr;
        grid-template-areas: " . page .";
        background-color: white;

        .inner {
            padding: 10px 10px;
            grid-area: page;
            display: grid;
            grid-template-columns: minmax(150px, 250px) auto;
            grid-template-row: max-content;
            justify-items: end;
            align-items: center;

            a:link, a:visited {
                color: #4996B6;
                text-decoration: none;
            }

            a:hover, a:active {
                text-decoration: underline;
            }

            .logo {
                grid-column-start: 1;
                grid-row-start: 1;
                cursor: pointer;

                svg {
                    width: 100%;
                }
            }

            .nav {
                grid-column-start: 2;
                grid-row-start: 2;
                display: flex;
                flex-direction: row;
            }
        }

        @media small-query {
            .inner {
                padding: 5px 10px;
                grid-template-columns: auto;
                grid-template-row: max-content max-content;
                justify-items: center;
                align-items: center;

                .logo {
                    grid-column-start: 1;
                    grid-row-start: 1;
                    grid-row-end: unset;

                    svg {
                        width: 150px;
                    }
                }

                .nav {
                    grid-column-start: 1;
                    grid-row-start: 2;
                }
            }
        }
    }
</style>
