<template>
    <div class="footer">
        <div class="body">
            <div class="title">Contact Us</div>
            <div class="item">
                <div class="icon"><AddressIcon/></div>
                <div class="label">Address: </div>
                <div class="value">1441 Seamist Dr. Houston, TX 77008</div>
            </div>
            <div class="item">
                <div class="icon"><PhoneIcon/></div>
                <div class="label">Phone: </div>
                <div class="value"><a href="tel:1-713-861-8993">713-861-8993</a></div>
            </div>
            <div class="item">
                <div class="icon"><EmailIcon/></div>
                <div class="label">Email: </div>
                <div class="value"><a href="mailto:sales@dewalch.com">sales@dewalch.com</a></div>
            </div>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import AddressIcon from '../assets/map.svg';
    import PhoneIcon from '../assets/phone.svg';
    import EmailIcon from '../assets/email.svg';

    export default Vue.extend({
        name: 'Footer',
        components: {AddressIcon, PhoneIcon, EmailIcon}
    });
</script>

<style scoped lang="stylus">
    @require '../variables.styl';

    .footer {
        background-color: footer-background;
        line-height: 1em;
        display: grid;
        grid-template-columns: 1fr minmax(min-page-width, max-page-width) 1fr;
        grid-template-areas: " . page .";

        .body {
            padding: 40px 10px;
            grid-area: page;

            .title {
                title-font();
                font-size: 20px;
                color: white;
                margin-bottom: 20px;
            }

            .item {
                display: flex;
                flex-direction: row;
                font-size: 13px;
                margin-bottom: 13px;

                .icon {
                    svg {
                        height: 1em;
                        width: 1em;
                        fill: text-color;
                    }
                    margin-right: 5px;
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .label {
                    font-weight: 600;
                    margin-right: 5px;
                }
            }
        }

        @media small-query {
            .body {
                padding: 20px 10px;
            }
        }
    }
</style>
