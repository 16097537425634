<template>
    <div ref="outer" class="gallery">
        <div class="image" @touchstart="touchStart" @touchend="touchEnd">
            <div ref="border" class="border" @transitionend="transitionEnd">
                <img :src="url" @load="imageLoaded">
            </div>
        </div>

        <div v-show="showLoading" class="loading">
            <Loading/>
        </div>

        <div ref="buttons" class="buttons">
            <button v-for="(image, i) in images" :key="i" @click="setImage(i)">{{ i+1 }}</button>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Loading from '../assets/loading.svg';

    const blank = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

    export default Vue.extend({
        name: 'Gallery',
        components: {Loading},
        props: {
            images: {
                type: Array,
                required: true
            }
        },
        data: () => ({
            url: blank,
            index: -1,
            touchStartCoords: undefined,
            showLoading: false,
            pendingResize: false
        }),
        mounted() {
            window.addEventListener('resize', this.resize);
            const border = this.$refs.border;
            border.style.height = `500px`;
            this.$nextTick(() => {
                this.setImage(0);
            });
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.resize);
            clearInterval(this.intervalId);
        },
        methods: {
            resize() {
                if (this.pendingResize)
                    return;

                this.pendingResize = true;
                requestAnimationFrame(() => {
                    const image = this.images[this.index];

                    const outer = this.$refs.outer;
                    const border = this.$refs.border;

                    const ratio = (outer.offsetWidth - 6) / 500;
                    const height = image.height * ratio;

                    border.style.height = `${height}px`;
                    this.pendingResize = false;
                });
            },
            setImage(index) {
                // if (this.pendingResize)
                //     console.log('pendingResize');
                if (this.index === index)
                    return;

                const buttons = this.$refs.buttons;
                const border = this.$refs.border;

                const image = this.images[index];
                this.showLoading = true;

                //set dimensions for start of animation
                border.style.height = `${border.offsetHeight - 6}px`;
                border.style['transition-property'] = 'height';
                this.url = blank;

                if (this.index !== -1)
                    buttons.children[this.index].classList.remove('active');
                buttons.children[index].classList.add('active');

                this.index = index;

                //set dimensions for end of animation
                requestAnimationFrame(() => {
                    this.resize();
                    this.url = image.url;
                });
            },
            transitionEnd() {
                const border = this.$refs.border;
                border.style.removeProperty('transition-property');
            },
            imageLoaded() {
                this.showLoading = false;
            },
            touchStart(ev) {
                const touch = ev.changedTouches[0];
                if (!touch)
                    return;

                this.touchStartCoords = {
                    X: touch.screenX,
                    Y: touch.screenY
                };
            },
            touchEnd(ev) {
                if (!this.touchStartCoords)
                    return;

                const touch = ev.changedTouches[0];
                if (!touch)
                    return;

                const xDelta = this.touchStartCoords.X - touch.screenX;
                const yDelta = this.touchStartCoords.Y - touch.screenY;
                const xDeltaAbs = Math.abs(xDelta);
                const yDeltaAbs = Math.abs(yDelta);

                //vertical swipe
                if (yDeltaAbs > xDeltaAbs)
                    return;

                //swipe was too short
                if (xDeltaAbs < 25)
                    return;

                if (xDelta < 0) {
                    //swipe right
                    this.setImage(this.index === 0
                        ? this.images.length - 1
                        : this.index - 1);

                } else {
                    //swipe left
                    this.setImage(this.index === this.images.length - 1
                        ? 0
                        : this.index + 1);
                }
            }
        }
    });
</script>

<style scoped lang="stylus">
    @require '../variables.styl';

    .gallery {
        display: grid;
        grid-template-rows: max-content 1fr;
        justify-items: center;
        grid-row-gap: 20px;
        width: 100%;

        .image {
            z-index: 4;
            grid-area: 1 / 1 / 1 / 1;
            align-self: center;
            width: 100%;

            .border {
                background-color: white;
                border: solid 3px text-color;
                transition-property: none;
                transition-duration: 0.3s;
                transition-timing-function: ease-out;

                display: flex;
                justify-content: center;
                align-items: center;
                overflow: hidden;
                z-index: 4;

                img {
                    flex: 1 1 auto;
                    display: block;
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }
            }
        }

        .loading {
            z-index: 5;
            grid-area: 1 / 1 / 1 / 1;
            align-self: center;
        }

        .buttons {
            display: flex;
            z-index: 6;

            button {
                display: block;
                position: relative;
                background-color: link-color;
                border: solid 2px link-color;
                width: 30px;
                height: 30px;
                border-radius: 15px;
                margin: 0 10px;
                cursor: pointer;
            }

            button:hover {
                background-color: link-active;
            }

            button.active {
                border: solid 2px text-color;
            }
        }
    }
</style>
