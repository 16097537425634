<template>
    <div class="navButton">
        <template v-if="url">
            <button tag="button" @click="click">{{ title }}</button>
        </template>
        <template v-else-if="links">
            <button :class="buttonClasses" @mouseenter="enter" @mouseleave="leave">{{ title }}<DownArrow/></button>
            <div v-show="visible" class="menu" @mouseenter="enter" @mouseleave="leave">
                <NavigationButton v-for="link in links" :key="link.title" :title="link.title" :url="link.url" :links="link.links"/>
            </div>
        </template>
    </div>
</template>

<script>
    import Vue from 'vue';
    import DownArrow from '../assets/dropdown.svg';

    export default Vue.extend({
        name: 'NavigationButton',
        components: {DownArrow},
        props: {
            title: {
                type: String,
                required: true
            },
            url: {
                type: String,
                default: undefined
            },
            links: {
                type: Array,
                default: undefined
            }
        },
        data: () => ({
            visible: false,
            timeoutId: undefined,
            buttonClasses: {
                dropdown: true,
                active: false
            }
        }),
        methods: {
            click() {
                this.leave();
                this.$router.push(this.url);
            },
            closeUp() {
                this.visible = false;
                this.buttonClasses.active = false;
                if (this.$parent.$options.name === this.$options.name)
                    this.$parent.closeUp();
            },
            enter() {
                this.visible = true;
                this.buttonClasses.active = true;
                clearTimeout(this.timeoutId);
            },
            leave() {
                clearTimeout(this.timeoutId);
                this.timeoutId = setTimeout(() => {
                    this.closeUp();
                }, 100);
            }
        }
    });
</script>

<style scoped lang="stylus">
    @require '../variables.styl';
    .navButton {
        button {
            title-font();
            background-color: unset;
            color: link-color;
            box-shadow: unset;
            margin-right: 5px;
            padding: 10px;
            vertical-align: middle;
            white-space: nowrap;

            svg {
                width: 1em;
                height: 1em;
                fill: link-color;
                vertical-align: middle;
                padding-left: 2px;
            }
        }

        &:last-child {
            margin-right: 0;
        }

        button:hover, button.active {
            background-color: link-color;
            color: white;
            box-shadow: unset;

            svg {
                fill: white;
            }
        }

        button.dropdown {
            border-radius: 2px 2px 0 0;
        }

        .menu {
            position: absolute;
            background-color: link-color;
            color: white;
            border-radius: 0 2px 2px 2px;
            min-width: 200px;

            button {
                color: white;
                background-color: unset;
                border-radius: unset;
                width: 100%;
                text-align: left;
            }

            button:last-child {
                border-radius: 0 0 2px 2px;
            }

            button:hover {
                background-color: link-active;
            }
        }
    }
</style>
