<template>
    <PageLayout>
        <PageTitle slot="top" :title="title"/>

        <div class="body">
            <div class="job" v-for="(job, i) in jobs" :key="i">
                <div :key="'jobTitle' + i" class="title" >{{ job.title }}</div>
                <div v-for="(section, i) in job.sections" class="section" :key="i">
                    <div v-if="section.heading" :key="'title' + i" class="heading">{{ section.heading }}</div>
                    <div :key="'text' + i" class="text" v-html="section.text"></div>
                </div>
            </div>
        </div>
    </PageLayout>
</template>

<script>
    import Vue from 'vue';
    import PageLayout from '../components/PageLayout';
    import PageTitle from '../components/PageTitle';

    export default Vue.extend({
        name: 'Careers',
        components: {PageLayout, PageTitle},
        data: () => ({
            title: 'Careers'
        }),
        created() {
            const data = require('../data/careers');

            this.jobs = data.jobs;
        }
    });
</script>

<style scoped lang="stylus">
    @require '../variables.styl';

    .body {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 20px;

        .job {
            .title {
                title-font();
                color: bold-color;
                font-size: 24px;
                font-weight: 600;
                line-height: 1em;
                margin-bottom: 20px;
            }

            .section {
                font-size: 16px;
                line-height: 1.5em;
                margin-bottom: 20px;

                .heading {
                    title-font();
                    color: link-color;
                    margin-bottom: 0.5em;
                }

                .text {
                }
            }
        }
    }

</style>
