module.exports = {
    'Manufacturing, Energy, Information Technology': {
        'United States': [
            // {
            //     name: 'Michael C. Wilde',
            //     title: 'Chief Operating Officer',
            //     methods: {
            //         Phone: '713-861-8993 x 236',
            //         Email: 'mwilde@dewalch.com'
            //     }
            // }
            {
                name: 'General Inquiries',
                title: 'Sales Support',
                methods: {
                    Phone: '713-861-8993 x 285',
                    Email: 'info@dewalch.com'
                }
            }
        ]
    },
    'Utility Security': {
        'International': [
            {
                name: 'Ricardo Herrero-Velarde',
                title: 'V.P. Sales & Marketing',
                methods: {
                    Phone: '1-713-861-8993 x 239',
                    Email: 'sales@dewalch.com'
                }
            },
            {
                name: 'Carla Resendiz',
                title: 'Sales Support',
                methods: {
                    'USA Phone': '1-713-861-8993 x 307',
                    'Mexico Phone': '+52-55-5362-7100/7400',
                    Email: 'cresendiz@dewalch.com'
                }
            }
        ],
        'Mexico': [
            // {
            //     name: 'Joaquin Ramirez',
            //     title: 'Sales Manager',
            //     methods: {
            //         Phone: '1-713-861-8993 x 308',
            //         Mobile: '+52-1-55-44487556',
            //         'Mexico Phone': '+52-55-5362-7100/7400',
            //         Email: 'jramirez@dewalch.com'
            //     }
            // },
            {
                name: 'Cynthia Castro',
                title: 'Sales Support',
                methods: {
                    'USA Phone': '1-713-861-8993 x 306',
                    'Mexico Phone': '+52-55-5362-7100/7400',
                    Email: 'ccastro@dewalch.com'
                }
            }
        ],
        'United States': [
            {
                name: 'Ricardo Herrero-Velarde',
                title: 'V.P. Sales & Marketing',
                methods: {
                    Phone: '1-713-861-8993 x 239',
                    Email: 'sales@dewalch.com'
                }
            },
            {
                name: 'Mike Stevens',
                title: 'Investor-owned and Public Power Utilities',
                methods: {
                    Phone: '1-713-294-1154',
                    Email: 'mstevens@dewalch.com'
                }
            },
            // {
            //     name: 'Francisco Banda',
            //     title: 'Cooperatives and Municipalities',
            //     methods: {
            //         Phone: '713-861-8993 x 285',
            //         Mobile: '713-518-6791',
            //         Email: 'fbanda@dewalch.com'
            //     }
            // },
            {
                name: 'General Inquiries',
                title: 'Sales Support',
                methods: {
                    Phone: '713-861-8993 x 285',
                    Email: 'info@dewalch.com'
                }
            }
        ]
    }
};
