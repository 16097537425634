<template>
    <PageLayout>
        <PageTitle slot="top" :title="title"/>

        <div class="body">
            <div v-for="(item, i) in affiliations" :key="i" class="affiliation">
                <img :alt="item.name" :src="item.image">
            </div>
        </div>
    </PageLayout>
</template>

<script>
    import Vue from 'vue';
    import PageLayout from '../components/PageLayout';
    import PageTitle from '../components/PageTitle';

    export default Vue.extend({
        name: 'Affiliations',
        components: {PageLayout, PageTitle},
        data: () => ({
            title: 'Affiliations'
        }),
        created() {
            this.affiliations = require('../data/affiliations');
        }
    });
</script>

<style scoped lang="stylus">
    @require '../variables.styl';

    .body {
        display: flex;
        flex-flow: row wrap;
        justify-content: center;

        .affiliation {
            border: solid 3px text-color;
            margin: 0 20px 40px 0;

            img {
                display: block;
                width: 224px;
                height: 224px;
            }
        }
    }
</style>
