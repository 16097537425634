import Vue from 'vue';
import Router from 'vue-router';
import Home from '@/pages/Home';
import AboutUs from '@/pages/AboutUs';
import History from '@/pages/History';
import OurTeam from '@/pages/OurTeam';
import Biography from '@/pages/Biography';
import Affiliations from '@/pages/Affiliations';
import Careers from '@/pages/Careers';
import ContactUs from '@/pages/ContactUs';
import Division from '@/pages/Division';
import Product from '@/pages/Product';

Vue.use(Router);

//static routes
const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    {
        path: '/about-us',
        name: 'About Us',
        component: AboutUs
    },
    {
        path: '/history',
        name: 'History',
        component: History
    },
    {
        path: '/our-team',
        name: 'Meet Our Team',
        component: OurTeam
    },
    {
        path: '/affiliations',
        name: 'Affiliations',
        component: Affiliations
    },
    {
        path: '/careers',
        name: 'Careers',
        component: Careers
    },
    {
        path: '/contact-us',
        name: 'Contact Us',
        component: ContactUs
        // // route level code-splitting
        // // this generates a separate chunk (about.[hash].js) for this route
        // // which is lazy-loaded when the route is visited.
        // component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    }
];

//create dynamic routes for division
const divisions = require('../data/divisions');
for (const division of divisions) {
    routes.push({
        path: division.url,
        name: division.title,
        component: Division
    });
}

//create dynamic routes for team member
const team = require('../data/team');
for (const item of team) {
    routes.push({
        path: item.url,
        name: item.name,
        component: Biography
    });
}


//create dynamic routes for products
const products = require('../data/products')();
for (const name in products) {
    const division = products[name];
    for (const category of division) {
        for (const product of category.products) {
            routes.push({
                path: product.url,
                name: product.title,
                component: Product
            });
        }
    }
}

//create router
const router = new Router({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior(to, from, savedPosition) {
        //https://router.vuejs.org/en/advanced/scroll-behavior.html
        //scroll to top if there isn't a savedPosition
        //savedPosition, is only available if this is a popstate navigation (triggered by the browser's back/forward buttons).
        if (savedPosition)
            return savedPosition;

        return { x: 0, y: 0 };
    }
});

//dynamic page title
router.beforeEach((to, from, next) => {
    document.title = `${to.name} - DeWalch Technologies, Inc.`;
    next();
});

export default router;
