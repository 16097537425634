module.exports = [
    {
        order: 1,
        name: 'Binz DeWalch',
        image: '/team/bdewalch.jpg',
        title: 'Chairman, CEO, and Founder',
        url: '/binz-dewalch',
        bio: [
            'Binz DeWalch is the Chairman, CEO, and founder of DeWalch Technologies, Inc.',
            'Binz has over thirty years of professional experience in sales, operations and engineering, with a deep expertise in mechanical and electrical engineering. In addition to his CEO role, he leads DeWalch’s internal research and development projects. Binz’s leadership has been essential to the company’s success in developing security hardware, products for the biotech market, software, hardware, embedded system development, automation equipment, and specialty machines. Mr. DeWalch holds over twenty patents and has more than twenty others pending.',
            'He holds a B.S.M.E. in Mechanical Engineering from the University of Texas at Austin.'
        ]
    },
    // {
    //     order: 2,
    //     name: 'Mark DeWalch',
    //     image: '/team/mdewalch.jpg',
    //     title: 'Co-owner and Executive Vice President',
    //     url: '/mark-dewalch',
    //     bio: [
    //         'Mark DeWalch is co-owner and Executive Vice President at DeWalch Technologies, Inc.',
    //         'Mark joined the company in 1993 and serves in various roles, including Chief Financial Officer. He has over 30 years of professional experience in finance, accounting, and strategic management. Mark began his career in 1984 with a small start-up company, now well known as Dell Computer Corporation, working as the sole employee of Michael Dell.',
    //         'After completing his M.B.A., Mark moved to New York to work for The Irving Trust Company as a commercial lending officer. Upon his return to Houston, Mark served as the CFO of startup ventures in retail and software development before joining DeWalch Technologies as Chief Financial Officer. Mark serves on several boards including publicly traded and large non-profit entities.',
    //         'He holds a B.B.A. in Finance and a M.B.A. with a concentration in Accounting from the University of Texas at Austin.'
    //     ]
    // },
    // {
    //     order: 3,
    //     name: 'Tyler Todd',
    //     image: '/team/ttodd.jpg',
    //     title: 'Vice President of Engineering',
    //     url: '/tyler-todd',
    //     bio: [
    //         'Tyler Todd is the Vice President of Engineering at DeWalch Technologies, Inc.',
    //         'Tyler has over twenty years of experience in engineering design and manufacturing. Since joining DeWalch in 2007, Tyler and his team of engineers have developed numerous products and improvements for which multiple patents have been issued. Many of these products contain internally developed tooling and automation equipment. Tyler oversees product development, quality control, and the fabrication of equipment for automating customer processes.',
    //         'Tyler received a B.S.M.E. in Mechanical Engineering and an M.B.A. from The University of Texas at Austin.'
    //     ]
    // },
    {
        order: 2,
        name: 'Ricardo Velarde',
        image: '/team/rvelarde.jpg',
        title: 'Vice President of Sales & Marketing',
        url: '/ricardo-velarde',
        bio: [
            'Ricardo Herrero-Velarde is the Vice President of Sales & Marketing at DeWalch Technologies, Inc.',
            'Ricardo has over twenty years of experience in sales and marketing. Since joining DeWalch in 1997, Ricardo has used his extensive knowledge in protecting revenue for the electric and gas industries to the benefit of international and domestic customers. Ricardo’s sales team enjoys working closely with electric and gas utility professionals in order to understand their needs. As a result, DeWalch has become an industry leader in product innovation to meet those needs. The resulting products have helped utilities protect their revenues and become more profitable. He actively participates in revenue protection panel discussions and has published articles on electricity theft and the importance of meter security.',
            'Ricardo received a B.B.A. in Marketing from the University of Houston and an M.B.A. in International Business and Marketing.'
        ]
    },
    {
        order: 3,
        name: 'Mike Mushinski',
        image: '/team/mmushinski.jpg',
        title: 'Vice President and Controller',
        url: '/mike-mushinski',
        bio: [
            'Michael Mushinski is the Vice President and Controller at DeWalch Technologies, Inc.',
            'Michael has over twenty-eight years of experience in accounting. Prior to joining DeWalch in 2012, Michael held a variety of accounting and management roles in the manufacturing and contract electronics industries. Throughout his career, Michael has worked in all accounting functions, including finance and management. At DeWalch, Michael has been responsible for all daily accounting functions and financial reporting.',
            'Michael received a B.B.A. in accounting from the University of Houston.'
        ]
    },
    {
        order: 4,
        name: 'Mike Evans',
        image: '/team/mevans.jpg',
        title: 'IP In-House Counsel',
        url: '/mike-evans',
        bio: [
            'Mike Evans is the Intellectual Property in-House Counsel at DeWalch Technologies, Inc.',
            'As IP In-House Counsel for nearly 12 years at DeWalch, Mike has focused on U.S. and international patent prosecution and trademark and IP protection in diverse technology areas related to locking and security devices used in the electric and gas utility industries, oil & gas equipment systems, as well as in life sciences including biotechnology devices and processes, particularly involving DNA sequencing and robotic systems, and in other technologies. Prior to joining the DeWalch team, Mike worked as a business and patent law attorney in private practice with several other attorneys. In addition, before becoming an attorney, Mike worked for over fifteen years in various industries, as a Mechanical Engineer, which provided him with diverse experience in aerospace with The Boeing Company, oil & gas, materials handling systems, computer networking and telecommunications, as well as engineering contract management, negotiation and business development experience in various technology areas.',
            'Mike received a B.S.M.E. from the Texas A&M University, and a J.D. from the South Texas College of Law'
        ]
    }
];
