<template>
    <PageLayout>
        <Slider slot="top" :slides="slides"/>
        <div class="body">
            <Heading v-for="title in titles"
                :key="title.title"
                :style="title.style"
                :title="title.title"/>
            <IconParagraph v-for="block in blocks"
                :key="block.title"
                :style="block.style"
                :icon="block.icon"
                :title="block.title"
                :text="block.text"
                :link="block.link"/>
        </div>
    </PageLayout>
</template>

<script>
    import Vue from 'vue';
    import PageLayout from '../components/PageLayout';
    import Slider from '../components/Slider';
    import Heading from '../components/Heading';
    import IconParagraph from '../components/IconParagraph';

    export default Vue.extend({
        name: 'Home',
        components: {PageLayout, Slider, Heading, IconParagraph},
        data: () => ({
            title: 'Home',
            slides: undefined,
            titles: undefined,
            blocks: undefined
        }),
        created() {
            const data = require('../data/home');
            this.slides = data.slides;
            this.titles = data.titles;
            this.blocks = data.blocks;
        }
    });
</script>

<style scoped lang="stylus">
    @require '../variables.styl';

    .body {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 30px;
        grid-row-gap: 30px;
        grid-template-areas: \
                "side-title main-title main-title" \
                "side-content main-top-left main-top-right" \
                "side-content main-bottom-left main-bottom-right";
    }

    @media medium-query {
        .body {
            grid-column-gap:20px;
            grid-row-gap: 20px;
        }
    }

    @media small-query {
        .body {
            grid-template-columns: 1fr;
            grid-column-gap:20px;
            grid-row-gap: 20px;
            grid-template-areas: \
                    "side-title" \
                    "side-content" \
                    "main-title" \
                    "main-top-left" \
                    "main-top-right" \
                    "main-bottom-left" \
                    "main-bottom-right";
        }
    }
</style>
