<template>
    <PageLayout>
        <PageTitle slot="top" :title="title"/>

        <div class="body">
            <div class="photo"><div class="border"><img :src="image"></div></div>
            <div class="bio">
                <div v-for="paragraph in bio" :key="paragraph" class="paragraph">{{ paragraph }}</div>
            </div>
        </div>
    </PageLayout>
</template>

<script>
    import Vue from 'vue';
    import PageLayout from '../components/PageLayout';
    import PageTitle from '../components/PageTitle';

    export default Vue.extend({
        name: 'Biography',
        components: {PageLayout, PageTitle},
        data: () => ({
            title: undefined,
            name: undefined,
            image: undefined,
            bio: undefined
        }),
        created() {
            const team = require('../data/team');
            for (const item of team) {
                if (item.url === this.$route.path) {
                    this.title = item.name;
                    this.name = item.name;
                    this.image = item.image;
                    this.bio = item.bio;

                    const meta = document.createElement('meta');
                    meta.setAttribute('name', 'description');
                    meta.setAttribute('content', this.bio.join(' '));
                    window.document.head.appendChild(meta);
                    return;
                }
            }
        },
        destroyed() {
            const nodes = window.document.head.querySelectorAll('meta[name=description]');
            for (const node of nodes)
                window.document.head.removeChild(node);
        }
    });
</script>

<style scoped lang="stylus">
    @require '../variables.styl';

    .body {
        display: grid;
        grid-template-columns: 256px 1fr;
        grid-template-rows: max-content max-content 1fr;
        grid-template-areas: \
            "photo bio";

        .photo {
            grid-area: photo;
            padding: 0 0 0 0;
            width: 256px;

            .border {
                border: solid 3px text-color;

                img {
                    display: block;
                }
            }
        }

        .bio {
            grid-area: bio;
            padding: 6px 20px;

            .paragraph {
                line-height: 1.5em;
                margin-bottom: 1em;
            }
        }
    }

    @media small-query {
        .body {
            grid-template-columns: 1fr;
            grid-template-areas: \
                "photo" \
                "bio";

            .photo {
                justify-self: center;
            }
        }
    }
</style>
