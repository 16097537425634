<template>
    <PageLayout>
        <PageTitle slot="top" :title="title"/>

        <div class="body">
            <div v-for="item in items" :key="item.year" class="entry">
                <div :style="item.style" class="year">{{ item.year }}</div>
                <div class="point"><component :is="item.svg"/></div>
                <div :style="item.pointStyle3" class="line"/>
                <div :style="item.style" class="image"><div class="border"><img :src="item.image"></div></div>
                <div :style="item.style" class="text">{{ item.text }}</div>
            </div>
        </div>
    </PageLayout>
</template>

<script>
    import Vue from 'vue';
    import PageLayout from '../components/PageLayout';
    import PageTitle from '../components/PageTitle';

    export default Vue.extend({
        name: 'History',
        components: {PageLayout, PageTitle},
        data: () => ({
            title: 'Our History'
        }),
        created() {
            const history = require('../data/history');

            this.items = history.sort((a, b) => a.year < b.year ? -1 : 1);

            for (let i = 0; i < this.items.length; i++) {
                const item = this.items[i];

                item.pointStyle1 = {};
                item.pointStyle3 = {};
                item.dividerStyle = {};

                if (i === 0) {
                    item.svg = require('../assets/history/start.svg');

                } else if (i === this.items.length - 1) {
                    item.svg = require('../assets/history/point.svg');
                    item.pointStyle3['background-color'] = 'white';
                    item.pointStyle3.background = 'linear-gradient(to bottom, #4996B6 50%, #ffffff 100%)';
                    item.dividerStyle.display = 'none';

                } else {
                    item.svg = require('../assets/history/point.svg');
                }
            }
        }
    });
</script>

<style scoped lang="stylus">
    @require '../variables.styl';

    .body {
        .entry {
            display: grid;
            grid-template-columns: max-content 32px auto max-content;
            grid-template-rows: max-content 1fr 20px;
            justify-items: center;

            .point {
                grid-row: 1;
                grid-column: 2;
                background-color: white;

                svg {
                    display: block;
                    width: 32px;
                    fill: link-color;
                }
            }

            .line {
                grid-row: 2 / span 2;
                grid-column: 2;
                height: 100%;
                background-color: link-color;
                width: 8px;
            }

            .image {
                grid-row: 1 / span 3;
                grid-column: 4;
                padding: 0 0 0 0;
                margin-bottom: 20px;

                .border {
                    border: solid 1px text-color;
                    padding: 5px;

                    img {
                        display: block;
                        height: 150px;
                        width: 200px;
                    }
                }
            }

            .year {
                grid-column: 1;
                grid-row: 1;
                title_font();
                font-size: 24px;
                color: link-color;
                padding: 0 10px 0 0;
                align-self: center;
            }

            .text {
                grid-column: 3;
                grid-row: 1 / span 2;
                font-size: 16px;
                padding: 16px 40px 0 40px;
                justify-self: start;
            }
        }
    }

    @media small-query {
        .body {
            .entry {
                display: grid;
                grid-template-columns: 32px 1fr max-content;
                grid-template-rows: max-content 1fr max-content;

                .point {
                    grid-row: 1;
                    grid-column: 1;
                }

                .line {
                    grid-row: 2 / span 2;
                    grid-column: 1;
                }

                .image {
                    grid-row: 1 / span 2;
                    grid-column: 3;
                    margin-bottom: 0;

                    .border {
                        padding: 3px;

                        img {
                            height: 75px;
                            width: 100px;
                        }
                    }
                }

                .year {
                    grid-row: 1;
                    grid-column: 2;
                    font-size: 20px;
                    padding-left: 10px;
                    justify-self: start;
                }

                .text {
                    grid-row: 2;
                    grid-column: 2;
                    font-size: 14px;
                    padding: 7px 10px 0 10px;
                    margin-bottom: 20px;
                }
            }
        }
    }
</style>
