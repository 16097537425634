<template>
    <PageLayout>
        <PageTitle slot="top" :title="title"/>

        <div class="body">
            <div v-for="(heading, i) in headings" :style="heading.style" :key="'heading' + i" class="heading">
                <div class="title">{{ heading.title }}</div>
                <hr>
            </div>

            <div v-for="(list, i) in lists" :style="list.style" :key="'list' + i" class="list">
                <div class="region">{{ list.region }}</div>
                <div v-for="contact in list.contacts" :key="contact.name" class="contact">
                    <div class="name">{{ contact.name }}</div>
                    <div v-if="title" class="title">{{ contact.title }}</div>
                    <template v-for="method in contact.methods">
                        <div :key="method.label + 'label'" class="label">{{ method.label }}</div>
                        <div :key="method.label + 'value'" class="value"><a :href="method.href">{{ method.value }}</a></div>
                    </template>
                </div>
            </div>
        </div>
    </PageLayout>
</template>

<script>
    import Vue from 'vue';
    import PageLayout from '../components/PageLayout';
    import PageTitle from '../components/PageTitle';
    import Heading from '../components/Heading';

    export default Vue.extend({
        name: 'ContactUs',
        components: {PageLayout, Heading, PageTitle},
        data: () => ({
            title: 'Contact Us'
        }),
        created() {
            const data = require('../data/contacts');

            this.lists = [];
            this.headings = [];

            for (const section in data) {
                const heading = {
                    title: section,
                    style: {}
                };

                switch (section) {
                    case 'Utility Security':
                        heading.style['grid-area'] = 'heading1';
                        break;
                    case 'Manufacturing, Energy, Information Technology':
                        heading.style['grid-area'] = 'heading2';
                        break;
                    default:
                        throw new Error('section not displayed');
                }

                this.headings.push(heading);

                for (const region in data[section]) {
                    const contacts = data[section][region];

                    const list = {
                        region,
                        contacts: [],
                        style: {}
                    };

                    switch (region) {
                        case 'United States':
                            list.style['grid-area'] = section === 'Utility Security'
                                ? 'us1'
                                : 'us2';
                            break;
                        case 'International':
                            list.style['grid-area'] = 'international';
                            break;
                        case 'Mexico':
                            list.style['grid-area'] = 'mexico';
                            break;
                        default:
                            throw new Error('region not displayed');
                    }

                    this.lists.push(list);

                    for (const contact of contacts) {
                        const displayContact = {
                            name: contact.name,
                            title: contact.title,
                            methods: []
                        };
                        list.contacts.push(displayContact);

                        for (const label in contact.methods) {
                            const value = contact.methods[label];

                            displayContact.methods.push({
                                label: `${label}:`,
                                value,
                                href: `${label === 'Email' ? 'mailto:' : 'tel:'}${value}`
                            });
                        }
                    }
                }
            }
        }
    });
</script>

<style scoped lang="stylus">
    @require '../variables.styl';

    .body {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-column-gap: 20px;
        grid-row-gap: 20px;
        align-items: start;
        grid-template-areas: \
            "heading1 heading1 heading2" \
            "us1 international  us2" \
            "us1 mexico us2";

        .heading {
            title-font();
            color: bold-color;
            font-size: 24px;
            font-weight: 600;
            align-self: end;
            line-height: 1.25em;
            .title {
                margin-bottom: 0.25em;
            }

            svg {
                height: 1em;
                fill: link-color;
                padding-right: 5px;
            }
        }

        .list {
            display: grid;
            grid-template-columns: max-content 1fr;
            grid-row-gap: 10px;
            justify-items: start;
            margin-bottom: 10px;

            .region {
                grid-column: 1;
                title-font();
                font-size: 20px;
                font-weight: 600;
                line-height: 1.25em;
            }

            .contact {
                display: grid;
                grid-column: 1;
                grid-template-columns: max-content 1fr;
                grid-auto-rows: 18px;
                align-items: end;
                grid-column-gap: 10px;
                grid-row-gap: 5px;
                margin-bottom: 10px;

                .name {
                    grid-column: 1 / span 2;
                    title-font();
                    font-weight: 600;
                }

                .title {
                    grid-column: 1 / span 2;
                }

                .label {
                    grid-column: 1;
                    font-weight: 600;
                }

                .value {
                    grid-column: 2;
                }
            }
        }
    }

    @media medium-query {
        .body {
            grid-template-columns: 1fr 1fr;
            grid-template-areas: \
                    "heading1 heading2" \
                    "us1 us2" \
                    "international us2" \
                    "mexico us2";
        }
    }

    @media small-query {
        .body {
            grid-template-columns: 1fr;
            grid-template-areas: \
                    "heading1" \
                    "us1" \
                    "international" \
                    "mexico" \
                    "heading2" \
                    "us2";
        }
    }
</style>
