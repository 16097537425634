<template>
    <div :style="sliderStyle" class="slider">
        <button class="prev" @click="prevImage"><LeftCarot/></button>
        <div v-for="slide in slides" v-show="slide.show" :key="slide.src" class="slide">
            <transition name="fade">
                <img v-show="slide.show" :src="slide.src" :key="slide.src">
            </transition>
            <transition name="fade-delay">
                <div v-show="slide.show" :key="slide.src" class="curtain"/>
            </transition>
            <transition name="fly-delay">
                <div v-show="slide.show" :key="slide.src" class="text">
                    <div class="title">{{ slide.title }}</div>
                    <div class="message">{{ slide.message }}</div>
                </div>
            </transition>
        </div>
        <button class="next" @click="nextImage"><RightCarot/></button>
    </div>
</template>

<script>
    import Vue from 'vue';
    import LeftCarot from '../assets/left_carot.svg';
    import RightCarot from '../assets/right_carot.svg';

    export default Vue.extend({
        name: 'Slider',
        components: {LeftCarot, RightCarot},
        props: {
            slides: {
                type: Array,
                required: true
            }
        },
        data: () => ({
            sliderStyle: {
                'height': '0'
            },
            imageIndex: -1
        }),
        mounted() {
            window.addEventListener('resize', this.resizeMargin);
            this.$nextTick(() => {
                this.resizeMargin();
                this.nextImage();
            });
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.resizeMargin);
            clearInterval(this.intervalId);
        },
        methods: {
            resizeMargin() {
                if (this.pendingResize)
                    return;

                this.pendingResize = true;
                requestAnimationFrame(() => {
                    const width = Math.min(1250, document.documentElement.clientWidth);
                    const height = width * 0.4;
                    this.sliderStyle.height = `${height}px`;
                    this.pendingResize = false;
                });
            },
            prevImage() {
                if (this.imageIndex > -1)
                    this.slides[this.imageIndex].show = false;

                if (--this.imageIndex < 0)
                    this.imageIndex = 3;

                this.slides[this.imageIndex].show = true;
                this.resetInterval();
            },
            nextImage() {
                if (this.imageIndex > -1)
                    this.slides[this.imageIndex].show = false;

                if (++this.imageIndex > 3)
                    this.imageIndex = 0;

                this.slides[this.imageIndex].show = true;
                this.resetInterval();
            },
            resetInterval() {
                clearInterval(this.intervalId);
                this.intervalId = setInterval(() => this.nextImage(), 10000);
            }
        }
    });
</script>

<style scoped lang="stylus">
    @require '../variables.styl';

    .slider {
        display: grid;
        grid-template-columns: 40px 1fr 40px;
        background-color: black;

        button {
            border-style: none;
            background-color: rgba(0, 0, 0, 0.7);
            z-index: 5;
            width: 40px;
            height: 40px;
            justify-self: center;
            align-self: center;
            text-align: center;
            vertical-align: center;
            box-shadow: unset;

            svg {
                height: 20px;
                fill: text-color;
            }
        }

        button.prev {
            grid-column: 1;
            grid-row: 1;
            border-radius: 0 6px 6px 0;
        }

        button.next {
            grid-column: 3;
            grid-row: 1;
            border-radius: 6px 0 0 6px;
        }

        .slide {
            backface-visibility: visible;
            grid-column-start: 1;
            grid-column-end: 4;
            grid-row: 1;
            z-index: 1;
            display: grid;
            justify-content: center;
            align-content: center;
            overflow: hidden;

            img {
                display: block;
                transform: translate3d(0,0,0); //normalizes position to whole pixels
                grid-column: 1;
                grid-row: 1;
                width: 100%;
            }

            div.curtain {
                background-color: black;
                opacity: 0.4;
                grid-column: 1;
                grid-row: 1;
                z-index: 3;
            }

            div.text {
                grid-column: 1;
                grid-row: 1;
                display: grid;
                z-index: 4;

                div.title {
                    grid-column: 1;
                    grid-row: 1;
                    color: white;
                    text-shadow: 0 1px 0 rgba(0, 0, 0, 0.5);
                    title-font();
                    font-weight: 600;
                    font-size: 48px;
                    width: 60%;
                    justify-self: center;
                    align-self: end;
                    text-align: center;
                    margin-bottom: 10px;
                }

                div.message {
                    grid-column: 1;
                    grid-row: 2;
                    color: white;
                    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.5);
                    font-size: 24px;
                    width: 80%;
                    justify-self: center;
                    align-self: top;
                    text-align: center;
                    margin-top: 10px;
                }
            }
        }

        .fade-enter-active, .fade-leave-active {
            transition: opacity 1s;
        }
        .fade-enter, .fade-leave-to {
            opacity: 0;
        }

        @keyframes fade-delay {
            0% {
                opacity: 0;
            }
            50% {
                opacity: 0;
            }
            100% {
                opacity: 0.4;
            }
        }

        .fade-delay-enter-active {
            animation: fade-delay 2s;
            transition: opacity 2s;
        }

        .fade-delay-leave-active {
            opacity: 0;
        }

        @keyframes fly-delay {
            0% {
                transform: translateY(100px);
                opacity: 0;
            }
            50% {
                transform: translateY(100px);
                opacity: 0;
            }
            100% {
            }
        }

        .fly-delay-enter-active {
            animation: fly-delay 2s;
        }

        .fly-delay-leave-active {
            opacity: 0;
        }
    }

    @media medium-query {
        .slider {
            .slide {
                div.text {

                    div.title {
                        font-size: 36px;
                    }

                    div.message {
                        font-size: 18px;
                    }
                }
            }
        }
    }

    @media small-query {
        .slider {
            .slide {
                div.text {

                    div.title {
                        font-size: 28px;
                        width: 80%;
                    }

                    div.message {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    @media very-small-query {
        .slider {
            .slide {
                div.text {

                    div.title {
                        font-size: 20px;
                        width: 80%;
                    }

                    div.message {
                        font-size: 14px;
                    }
                }
            }
        }
    }
</style>
