module.exports = [
    {
        title: 'Home',
        url: '/'
    },
    {
        title: 'Company',
        links: [
            {
                title: 'About Us',
                url: '/about-us'
            },
            {
                title: 'History',
                url: '/history'
            },
            // {
            //     title: 'Our Team',
            //     url: '/our-team'
            // }
        ]
    },
    {
        title: 'Products And Services',
        links: [
            {
                title: 'Utility Security',
                url: '/utility-security'
            },
            {
                title: 'Contract Manufacturing',
                url: '/contract-manufacturing'
            },
            {
                title: 'Energy',
                url: '/energy'
            },
            {
                title: 'Information, Control, and Automation Systems',
                url: '/information-systems'
            }
        ]
    },
    {
        title: 'Affiliations',
        url: '/affiliations'
    },
    {
        title: 'Careers',
        url: '/careers'
    },
    {
        title: 'Contact Us',
        url: '/contact-us'
    }
];
