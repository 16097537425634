<template>
    <div v-once class="block">
        <div class="title">{{ title }}</div>
        <div v-if="icon" class="icon"><component :is="getIcon"/></div>
        <div class="text">
            <div v-for="line in text" :key="line" class="line">{{ line }}</div>
        </div>
        <div v-if="link" class="links">
            <router-link :to="link" tag="button">Read More</router-link>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        name: 'IconParagraph',
        props: {
            icon: {
                type: String,
                default: undefined
            },
            title: {
                type: String,
                required: true
            },
            text: {
                type: Array,
                required: true
            },
            link: {
                type: String,
                default: undefined
            }
        },
        computed: {
            getIcon() {
                if (!this.icon)
                    return undefined;

                return require(`../assets/${this.icon}.svg`);
            }
        }
    });
</script>

<style scoped lang="stylus">
    @require '../variables.styl';

    .block {
        display: grid;
        grid-template-rows: max-content max-content max-content;
        grid-template-columns: max-content auto;
        grid-row-gap: 10px;

        .title {
            title-font();
            grid-column-start: 2;
            grid-row-start: 1;
            font-size: 16px;
        }

        .icon {
            grid-column-start: 1;
            grid-row-start: 1;
            grid-row-end: span 3;
            svg {
                width: 2em;
                fill: link-color;
            }
            margin-right: 10px;
        }

        .text {
            grid-column-start: 2;
            grid-row-start: 2;
            line-height: 1.5em;

            .line {
                margin-bottom: 14px;
            }

            .line:last-child {
                margin-bottom: 0;
            }
        }

        .links {
            grid-row-start: 3;
            grid-column-start: 2;
            line-height: 1em;
        }
    }
</style>
