module.exports = {
    heading: 'A place where amazing things Get Done!',
    description: [
        'DeWalch Technologies, Inc., founded in 1982 by Binz DeWalch and Don DeWalch, is a vertically integrated engineering, development and manufacturing company with 4 principal areas of focus: Security, Energy, Manufacturing, and Integrated Digital Systems. The company was founded as a platform for the development, manufacturing and commercialization of technology-based products and internal utilization of technology-based manufacturing techniques. The DeWalch Companies are comprised of several other affiliated entities with common ownership.',
        'The company’s founding division is a manufacturer of specialty locks, locking hardware and systems used for locking gas, electricity and water meters for the prevention of theft of service. These products are protected by numerous U.S. and international patents with many other applications pending. The patented DeWalch designs are particularly effective when customers require keys that are extremely hard to counterfeit and locks that are very weather and tamper resistant.',
        'Using skills and experience gained over the years in addressing the needs of a demanding initial market, DeWalch has developed products, solutions and capabilities to address needs in many other market areas including biotech, precision contract manufacturing, software and hardware development, automation, and petrochemical/fuels process and equipment design/fabrication. Many of these applications were born out of outside parties recognizing DeWalch’s unique blend of talents and capabilities, and seeking DeWalch’s expertise to develop solutions.',
        'The DeWalch family descends from a long line of entrepreneurs with a history of commerce and manufacturing.'
    ],
    companies: [
        {
            title: 'Black Stone Minerals Company, successor to W.T. Carter and Brother',
            details: 'Traded on the NYSE (BSM). Largest mineral owner in the U.S. with over 20 million mineral acres'
        },
        {
            title: 'Weslayan Bank',
            date: '(1983)',
            location: 'Houston, Texas'
        },
        {
            title: 'San Felipe National Bank',
            date: '(1973)',
            location: 'Houston, Texas'
        },
        {
            title: 'Fannin Bank',
            date: '(1948)',
            location: 'Houston, Texas'
        },
        {
            title: 'Southern Electric Supply Company',
            date: '(1934)',
            location: '6 locations in Texas'
        },
        {
            title: 'W.T. Carter Field',
            date: '(1927)',
            location: 'Houston, Texas',
            details: 'Houston Airport Corporation, now Hobby Airport'
        },
        {
            titles: [
                {
                    title: 'South Texas National Bank',
                    date: '(1901)'
                },
                {
                    title: 'Union Bank and Trustl',
                    date: '(1905)'
                },
                {
                    title: 'Union National Bank',
                    date: '(1910)'
                },
                {
                    title: 'Texas National Bank',
                    date: '(1953)'
                },
                {
                    title: 'Texas Commerce Bank',
                    date: '(1964)'
                }
            ],
            location: 'Houston, Texas',
            details: 'Carter and Binz families founding or significant shareholders of each Bank'
        },
        {
            title: 'Worth Made Clothing Company',
            date: '(Early 1900’s)',
            location: 'Ft. Worth, Texas'
        },
        {
            title: 'Schumacher Bank',
            date: '(1900)',
            location: 'La Grange, Texas'
        },
        {
            title: 'Houston Car Wheel & Machine',
            date: '(Early 1900’s)',
            location: 'Houston, Texas',
            details: 'location of development of first drill bit by Howard Hughes'
        },
        {
            title: 'Moscow, Camden and San Augustine Railroad',
            date: '(1898)',
            location: 'Camden, Texas'
        },
        {
            title: 'W.T. Carter and Brother Lumber Company',
            date: '(1876)',
            location: 'Polk County, Texas'
        },
        {
            title: 'The Schuhmacher Company',
            date: '(1868)',
            location: 'La Grange, Texas & Houston, Texas',
            details: 'Grocery distribution'
        },
        {
            title: 'Binz Brewery',
            date: '(1853)',
            location: 'Sheboygan, Wisconsin & Chicago, Illinois'
        },
        {
            title: 'Telelectric',
            date: '(Late 1800’s)',
            location: 'Houston, Texas',
            details: 'Wholesale electric supplier'
        }
    ],
    values: [
        {
            title: 'Vision',
            icon: 'vision',
            text: ['Our vision is to build an organization dedicated to the conception, development and commercialization of technology that profoundly benefits humanity and funds further development.']
        },
        {
            title: 'Customer Focus',
            icon: 'focus',
            text: ['Our customers continue to select our products because we provide Quality, Security, Innovation, and Value.']
        },
        {
            title: 'Commitment to Quality',
            icon: 'quality',
            text: ['DeWalch Quality System is an initiative involving every aspect of its business. Everyone who works for or with DeWalch is empowered and expected to maintain the highest standards of quality in products, processes and relationships.']
        }
    ]
};

