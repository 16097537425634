<template>
    <PageLayout>
        <PageTitle slot="top" :title="title"/>

        <div :class="{'body-no-image': !image}" class="body">
            <div v-if="image" class="image"><div class="border"><img :src="image"></div></div>
            <div class="description">
                <div v-for="paragraph in description" :key="paragraph" class="paragraph" v-html="paragraph"/>
            </div>

            <div v-if="categories" class="categories">
                <div v-for="category in categories" :key="category.name" class="category">
                    <div class="name">{{ category.name }}</div>

                    <div class="products">
                        <router-link v-for="product in category.products" :key="product.name" :to="product.url" tag="div" class="product">
                            <div class="thumb"><img :src="product.thumb"></div>
                            <div class="title">{{ product.title }}</div>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </PageLayout>
</template>

<script>
    import Vue from 'vue';
    import PageLayout from '../components/PageLayout';
    import PageTitle from '../components/PageTitle';

    export default Vue.extend({
        title: 'Division',
        components: {PageLayout, PageTitle},
        data: () => ({
            title: undefined,
            image: undefined,
            description: undefined,
            products: undefined
        }),
        watch: {
            '$route': 'loadData'
        },
        created() {
            this.loadData(this.$route);
        },
        methods: {
            loadData(route) {
                const divisions = require('../data/divisions');
                let division;

                for (division of divisions) {
                    if (division.url === route.path) {
                        this.title = division.title;
                        this.image = division.image;
                        this.description = division.description;
                        break;
                    }
                }

                const products = require('../data/products')();
                const categories = products[division.title];
                this.categories = [];
                if (categories) {
                    for (const category of categories) {
                        this.categories.push(category);

                        for (const product of category.products)
                            product.thumb = `/products${product.url}/thumb.jpg`;
                    }
                }
            }
        }
    });
</script>

<style scoped lang="stylus">
    @require '../variables.styl';

    .body {
        display: grid;
        grid-template-columns: 306px 1fr;
        grid-template-rows: max-content max-content 1fr;
        grid-row-gap: 20px;
        grid-template-areas: \
            "image description" \
            "products products";

        .image {
            grid-area: image;
            padding: 0 0 0 0;
            width: 306px;

            .border {
                border: solid 3px text-color;

                img {
                    display: block;
                }
            }
        }

        .description {
            grid-area: description;
            padding: 6px 20px;

            .paragraph {
                line-height: 1.5em;
                margin-bottom: 1em;
            }
        }

        .categories {
            grid-area: products;

            .category {
                .name {
                    title-font();
                    font-weight: 600;
                    font-size: 16px;
                    margin-bottom: 10px;
                }

                .products {
                    display: flex;
                    flex-flow: row wrap;
                    justify-content: center;
                    align-items: stretch;

                    .product {
                        display: grid;
                        flex-shrink: 1;
                        width: 206px;
                        margin: 0 20px 40px 0;
                        justify-items: center;
                        cursor: pointer;

                        .thumb {
                            grid-row: 1;
                            grid-column: 1;
                            border: solid 3px text-color;
                            width: 200px;
                            height: 200px;

                            img {
                                display: block;
                                width: 100%;
                                height: 100%;
                            }
                        }

                        .title {
                            grid-row: 2;
                            grid-column: 1;
                            title-font();
                            color: link-color;
                            font-size: 14px;
                            padding-top: 8px;
                            text-align: center;
                        }
                    }
                }
            }
        }
    }

    @media small-query {
        .body {
            grid-template-columns: 1fr;
            grid-template-areas: \
                "image" \
                "description" \
                "products";

            .image {
                justify-self: center;
            }

            .categories {
                .category {
                    .products {
                        .product {
                            width: 156px;

                            .thumb {
                                width: 150px;
                                height: 150px;
                            }
                        }
                    }
                }
            }
        }
    }

    @media very-small-query {
        .body {
            .categories {
                .category {
                    .products {
                        .product {
                            width: 104px;

                            .thumb {
                                border: solid 2px text-color;
                                width: 100px;
                                height: 100px;
                            }
                        }
                    }
                }
            }
        }
    }

    .body-no-image {
        grid-template-columns: 1fr;
        grid-template-areas: \ "description" \
        "products";
    }
</style>
