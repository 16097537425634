module.exports = {
    slides: [
        {
            src: '/slider/image1.jpg',
            show: false,
            title: 'Contract Manufacturing',
            message: 'From concept design through production to final integration and logistics, DeWalch is vertically integrated to service your needs providing a complete Box-Build strategy.'
        },
        {
            src: '/slider/image2.jpg',
            show: false,
            title: 'Utility Security',
            message: 'DeWalch was founded as a platform for the development and commercialization of technology and technology-based solutions.'
        },
        {
            src: '/slider/image3.jpg',
            show: false,
            title: 'Information Systems & Controls and Automation',
            message: 'Creating platforms for a connected world.  From plant floor automation, machine design, control system,  the “internet of things” and connected computing, DeWalch continues to hone its cutting edge expertise.'
        },
        {
            src: '/slider/image4.jpg',
            show: false,
            title: 'Energy',
            message: 'Our unique perspectives and novel approaches bring new creativity to solve the industry’s challenges.'
        }
    ],
    titles: [
        {
            title: 'Who We Are',
            style: {
                'grid-area': 'side-title'
            }
        },
        {
            title: 'Our Products & Services',
            style: {
                'grid-area': 'main-title'
            }
        }
    ],
    blocks: [
        {
            title: 'DeWalch Techonolgies, Inc.',
            style: {
                'grid-area': 'side-content'
            },
            text: [
                'DeWalch Technologies is dedicated to the conception, development and commercialization of leading edge technology that satisfies market needs and funds further development.',
                'Founded in 1982, our Company quickly became a leading manufacturer of specialty meter locking hardware for the prevention of theft of energy and promotion of safety in Electric and Gas industries. The Company continues to lead and innovate and has used its disciplines to synergistically expand into additional markets.',
                'Our corporate headquarters are in Houston, with sales operations in numerous countries around the world.'
            ],
            link: '/about-us'
        },
        {
            title: 'Utility Security',
            style: {
                'grid-area': 'main-top-left'
            },
            icon: 'security',
            text: ['DeWalch was founded as a platform for the development and commercialization of technology and technology-based solutions. The first opportunity and still a principal focus of the company is in the area of securing the point of service for utility companies.'],
            link: '/utility-security'
        },
        {
            title: 'Contract Manufacturing',
            style: {
                'grid-area': 'main-top-right'
            },
            icon: 'manufacturing',
            text: ['The DeWalch companies offer a wide range of custom manufacturing capabilities spanning many different disciplines. The history of our efforts, rooted in Lean principles, is heavily influenced by our strength in engineering, and our philosophy has always been to design for manufacturing.'],
            link: '/contract-manufacturing'
        },
        {
            title: 'Energy',
            style: {
                'grid-area': 'main-bottom-left'
            },
            icon: 'energy',
            text: ['The DeWalch family has a long association with the energy industry, which has presented an opportunity for exposure to unmet needs in the market. Bringing our creative insights, not rooted in traditional industry solutions, we have adapted novel approaches to address industry challenges.'],
            link: '/energy'
        },
        {
            title: 'Information Systems & Controls and Automation',
            style: {
                'grid-area': 'main-bottom-right'
            },
            icon: 'computers',
            text: ['Born of a need to solve internal demands, DeWalch has developed deep expertise in software, hardware development, and design of automation, connected systems, embedded controls, and special purpose machines.'],
            link: '/information-systems'
        }
    ]
};
