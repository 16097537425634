<template>
    <PageLayout>
        <PageTitle slot="top" :title="title"/>

        <div class="body">
            <Gallery :images="images" class="gallery"/>
            <div class="title">{{ title }}</div>
            <div class="description" v-html="description"/>
            <div class="sections">
                <div v-for="(section, i) in sections" :key="i" class="section">
                    <div class="title">{{ section.name }}</div>
                    <ul>
                        <li v-for="(feature, i) in section.features" :key="i" class="feature" v-html="feature"/>
                    </ul>
                </div>
            </div>
        </div>
    </PageLayout>
</template>

<script>
    import Vue from 'vue';
    import PageLayout from '../components/PageLayout';
    import PageTitle from '../components/PageTitle';
    import Gallery from '../components/Gallery';

    export default Vue.extend({
        name: 'Product',
        components: {PageLayout, PageTitle, Gallery},
        data: () => ({
            title: undefined,
            description: undefined,
            sections: undefined,
            images: undefined
        }),
        created() {
            const products = require('../data/products')();
            const productImages = require('../data/product-images.json');

            for (const name in products) {
                const division = products[name];
                for (const category of division) {
                    for (const product of category.products) {

                        if (product.url === this.$route.path) {
                            this.title = product.title;
                            this.description = product.description;
                            this.sections = product.sections;

                            this.images = [];
                            const productUrl = `/products${product.url}`;

                            const images = productImages[productUrl];
                            for (const image in images) {
                                this.images.push({
                                    url: `${productUrl}/${image}`,
                                    width: images[image][0],
                                    height: images[image][1]
                                });
                            }

                            const meta = document.createElement('meta');
                            meta.setAttribute('name', 'description');
                            meta.setAttribute('content', this.description);
                            window.document.head.appendChild(meta);
                            return;
                        }
                    }
                }
            }
        },
        destroyed() {
            const nodes = window.document.head.querySelectorAll('meta[name=description]');
            for (const node of nodes)
                window.document.head.removeChild(node);
        }
    });
</script>

<style scoped lang="stylus">
    @require '../variables.styl';

    .body {
        display: grid;
        grid-template-columns: 506px 1fr;
        grid-template-rows: max-content max-content 1fr;
        grid-column-gap: 40px;
        grid-row-gap: 30px;
        grid-template-areas: \
            "gallery title" \
            "gallery description" \
            "gallery features";

        .title {
            grid-area: title;
            title-font();
            font-size: 24px;
            font-weight: 600;
        }

        .gallery {
            grid-area: gallery;
            max-width: 506px;
            justify-self: stretch;

            .border {
                border: solid 3px text-color;

                img {
                    display: block;
                }
            }
        }

        .description {
            grid-area: description;
            font-size: 18px;
            line-height: 1.5em;
        }

        .sections {
            grid-area: features;
            .section {
                font-size: 18px;
                line-height: 1.5em;

                .title {
                    title-font();
                    color: link-color;
                }

                .feature {
                    font-size: 14px;
                }
            }
        }
    }

    @media medium-query {
        .body {
            grid-template-columns: 1fr minmax(100px, 506px) 1fr;
            grid-column-gap: 30px;
            grid-row-gap: 20px;

            grid-template-areas: \
                ". gallery ." \
                "title title title" \
                "description description description" \
                "features features features";

            .gallery {
                max-width: 506px;
            }
        }
    }

    @media small-query {
        .body {
        }
    }
</style>
