<template>
    <div v-once class="title">{{ title }}</div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        name: 'Heading',
        props: {
            title: {
                type: String,
                required: true
            }
        }
    });
</script>

<style scoped lang="stylus">
    @require '../variables.styl';

    .title {
        title-font();
        color: bold-color;
        font-size: 24px;
    }
</style>
