module.exports = [
    {
        name: 'euserc',
        image: '/affiliations/EUSERC.jpg'
    },
    {
        name: 'glems',
        image: '/affiliations/GLEMS.jpg'
    },
    {
        name: 'iurpa',
        image: '/affiliations/IURPA.jpg'
    },
    {
        name: 'meta',
        image: '/affiliations/META.jpg'
    },
    {
        name: 'ncscrpc',
        image: '/affiliations/NCSCRPC.jpg'
    },
    {
        name: 'nurpa',
        image: '/affiliations/NURPA.jpg'
    },
    {
        name: 'nurpa',
        image: '/affiliations/NURPA1.jpg'
    },
    {
        name: 'scrpa',
        image: '/affiliations/SCRPA.jpg'
    },
    {
        name: 'surpa',
        image: '/affiliations/SURPA.jpg'
    },
    {
        name: 'swema',
        image: '/affiliations/SWEMA.jpg'
    },
    {
        name: 'wsuta',
        image: '/affiliations/WSUTA.jpg'
    }
];
