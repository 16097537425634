<template>
    <div id="app">
        <div id="header" ref="header"><Header/></div>
        <div id="content" ref="content"><router-view/></div>
        <div id="footer"><Footer/></div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import Header from './components/Header';
    import Footer from './components/Footer';

    export default Vue.extend({
        name: 'App',
        components: {Header, Footer},
        mounted() {
            window.addEventListener('resize', this.resizeMargin);
            this.$nextTick(() => {
                this.resizeMargin();
            });
        },
        beforeDestroy() {
            window.removeEventListener('resize', this.resizeMargin);
        },
        methods: {
            resizeMargin() {
                requestAnimationFrame(() => {
                    this.$refs.content.style['margin-top'] = `${this.$refs.header.clientHeight}px`;
                });
            }
        }
    });
</script>

<style src="./fonts.styl" lang="stylus"></style>
<style lang="stylus">
    @require './variables.styl';

    html, body {
        padding: 0;
        margin: 0;
        font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Helvetica Neue", "Ubuntu", "Cantarell", "Droid Sans", sans-serif;
        font-size: 14px;
        color: text-color;
    }

    a:link, a:visited {
        color: link-color;
        text-decoration: underline;
    }

    a:hover, a:active {
        text-decoration: underline;
    }

    button {
        background-color: link-color;
        color: white;
        line-height: 1em;
        padding: 8px;
        border-style: none;
        border-radius: 2px;
        cursor: pointer;
        box-shadow: 0 2px 2px 0 rgba(0,0,0,0.12), 0 1px 5px 0 rgba(0,0,0,0.1), 0 3px 1px -2px rgba(0,0,0,0.18);
    }

    button:hover {
        background-color: link-active;
    }

    hr {
        background-color: hr-color;
        border: 0;
        height: 2px;
        margin: 0 0;
    }
</style>

<style lang="stylus" scoped>
    #app {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;

        #header {
            position: fixed;
            left: 0;
            top: 0;
            width: 100%;
            z-index: 2;
        }

        #content {
            position: relative;
            z-index: 0;
        }

        #footer {
            z-index: 1;
        }
    }
</style>
