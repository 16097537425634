module.exports = [
    {
        title: 'Utility Security',
        url: '/utility-security',
        description: [
            'DeWalch was founded as a platform for the development and commercialization of technology and technology-based solutions. The first opportunity and still a principal focus of the company is in the area of securing the point of service for utility companies.',
            'DeWalch revolutionized the industry in the mid-1980s with the introduction of the first high security locking system, offering utility companies a paradigm shift in product features and technology. With the DeWalch high security lock and key, utility companies were able to exercise complete control over their systems with exclusive key codes, multi-generational locking systems, and keys incapable of being duplicated or counterfeited.',
            'DeWalch continues to develop leading edge products across the full range of applications in the utility industry. Most recently, DeWalch introduced the first electronic lock and key specifically designed for utility applications. A multi-year effort has produced a suite of hardware and software products that give our customers complete control over their locking systems, with such features as lock-specific and schedule-based key control and lost key self-destruction.'
        ]
    },
    {
        title: 'Contract Manufacturing',
        image: '/divisions/contract-manufacturing.jpg',
        url: '/contract-manufacturing',
        description: [
            'The DeWalch companies offer a wide range of manufacturing capabilities spanning many different disciplines. The history of our efforts is heavily influenced by our strength in engineering, and our philosophy has always been to “design for manufacturing”. Our design engineers routinely dedicate a substantial portion of their time on the plant floor, and are responsible for getting their designs into production. This philosophy breeds a keen awareness of reliability, efficiency, productivity and cost control, which are critical objectives in all that we produce.',
            'Recognizing our strengths in design of products, tooling, automation systems and specialty machines, DeWalch began offering manufacturing services to outside firms. This effort has resulted in a division of the company that is dedicated to these efforts.',
            'Serving a wide variety of industries, DeWalch routinely manufactures precision parts for our partners, and provides design engineering and production engineering where needed to improve a part\'s design for manufacture.',
            'Our capabilities include Vertical Machining, Turning, Stamping, Production and Custom Welding, Electronic Discharge Machining (EDM), Fabrication, Heat Treating, Assembly, CNC Laser Cutting, and Final Integration.',
            'Our leadership team with over 150 years of combined manufacturing experience stands ready and eager to help with each challenge presented to them.'
        ]
    },
    {
        title: 'Energy',
        image: '/divisions/energy.jpg',
        url: '/energy',
        description: [
            'The DeWalch family has a long association with the energy industry through an affiliation with a diversified mineral and royalty company.',
            'This company’s dominant position in the Haynesville shale formation and DeWalch’s historical connection to the oil and gas industry provided unique insight into the future abundance of natural gas. With knowledge gained in research of biofuels and the catalytic conversion of biomass to liquid fuels, DeWalch has embarked upon a project to convert abundant and cheap natural gas into higher-value synthetic liquid fuels.',
            'DeWalch now has a full staff of personnel devoted to the development and commercialization of the proprietary techniques and equipment for this initiative. Small scale prototype processing equipment is under construction and being commissioned for initial trials, and full scale production is planned for the near future.'
        ]
    },
    {
        title: 'Information Systems & Controls and Automation',
        image: '/divisions/information-systems.jpg',
        url: '/information-systems',
        description: [
            'Born of a need to solve internal demands, DeWalch has developed deep expertise in software and hardware development and design.',
            'DeWalch has developed software applications for a variety of applications, including:<ul><li>Enterprise Resource Planning (ERP)</li><li>Enterprise Project Management</li><li>Legal Docket Management</li><li>Electronic Lock and Key Management and Authorization</li><li>iPhone and Android app tools for Utility Key Management</li></ul>',
            'Current projects include hardware for electronic lock and key systems, specialized manufacturing equipment and equipment control, CAM bus, and LIN bus.'
        ]
    }
];
