module.exports = [
    {
        year: '1982',
        image: '/history/1982.jpg',
        text: 'Don DeWalch learns of opportunity for an improved locking system and collaborates with Binz DeWalch who begins development'
    },
    {
        year: '1985',
        image: '/history/1985.jpg',
        text: 'DeWalch Technologies, Inc. chartered with the Secretary of State of Texas'
    },
    {
        year: '1988',
        image: '/history/1988.jpg',
        text: 'Patent 4,742,703 “Cylinder Lock and Key with Rotating Elements” issued to Binz and Don DeWalch'
    },
    {
        year: '1990',
        image: '/history/1990.jpg',
        text: 'First Manufacturing Plant purchased'
    },
    {
        year: '1991',
        image: '/history/1991.jpg',
        text: 'First large scale delivery of products to Houston Lighting and Power'
    },
    {
        year: '1996',
        image: '/history/1996.jpg',
        text: 'First international sales to Mexico electric utility Comisión Federal de Electricidad'
    },
    {
        year: '1999',
        image: '/history/1999.jpg',
        text: 'Biotech division created to address needs for better automation in Life Sciences Market'
    },
    {
        year: '2010',
        image: '/history/2010.jpg',
        text: 'Development of Gas to Liquids technology begins'
    },
    {
        year: '2015',
        image: '/history/2015.jpg',
        text: 'Move to Larger Facility'
    },
    {
        year: '2016',
        image: '/history/2016.jpg',
        text: 'Next generation Electronic Lock and Key introduced to Electric Utility Market'
    }
];
