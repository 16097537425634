<template>
    <PageLayout>
        <PageTitle slot="top" :title="title"/>

        <div class="body">
            <Heading :title="heading" class="heading"/>
            <div class="about">
                <p v-for="(paragraph, i) in description" :key="i">{{ paragraph }}</p>
            </div>

            <div class="companies">
                <div class="title">Companies founded by ancestors of the owners:</div>
                <ul>
                    <li v-for="(company, i) in companies" :key="i">
                        <span v-if="company.title" class="title">{{ company.title }}</span>
                        <span v-if="company.date" class="date">{{ company.date }}</span>
                        <template v-for="(title, i) in company.titles">
                            <span :key="'title' + i" class="title">{{ title.title }}</span>
                            <span v-if="title.date" :key="'date' + i" class="date">{{ title.date }}</span>
                            <span v-if="i !== company.titles.length-1" :key="'comma' + i" class="comma">,</span>
                        </template>

                        <span v-if="company.location" class="location">{{ company.location }}</span>
                        <span v-if="company.details" class="dash">-</span>
                        <span class="details">{{ company.details }}</span>
                    </li>
                </ul>
            </div>

            <hr>

            <div class="values">
                <IconParagraph v-for="block in values"
                    :key="block.title"
                    :style="block.style"
                    :icon="block.icon"
                    :title="block.title"
                    :text="block.text"
                    :link="block.link"/>
            </div>
        </div>
    </PageLayout>
</template>

<script>
    import Vue from 'vue';
    import PageLayout from '../components/PageLayout';
    import PageTitle from '../components/PageTitle';
    import Heading from '../components/Heading';
    import IconParagraph from '../components/IconParagraph';

    export default Vue.extend({
        name: 'AboutUs',
        components: {PageLayout, Heading, IconParagraph, PageTitle},
        data: () => ({
            title: 'About Us'
        }),
        created() {
            const data = require('../data/aboutus');

            this.heading = data.heading;
            this.description = data.description;
            this.companies = data.companies;

            for (let i = 0; i < data.values.length; i++) {
                data.values[i].style = {
                    'grid-area': `para${i + 1}`
                };
            }
            this.values = data.values;
        }
    });
</script>

<style scoped lang="stylus">
    @require '../variables.styl';

    .body {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 20px;
        grid-template-areas: \
            "heading" \
            "about" \
            "companies" \
            "hr" \
            "values";

        .heading {
            grid-area: heading;
        }

        .about {
            grid-area: about;
        }

        .companies {
            grid-area: companies;

            .title {
                title-font();
                margin-bottom: 5px;
            }

            li {
                margin: 1em 0;

                .title {
                    title-font();
                    font-weight: 600;
                }

                .location {
                    font-style: italic;
                    margin-left: 10px;
                }

                .date {
                    title-font();
                    font-style: italic;
                    margin-left: 10px;
                }

                .dash {
                    margin: 0 10px;
                }

                .comma {
                    margin-right: 10px;
                }

                .details {
                }
            }
        }

        hr {
            grid-area: hr;
        }

        .values {
            grid-area: values;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            grid-column-gap: 20px;
            grid-row-gap: 20px;
            grid-template-areas: \
                "para1 para2 para3";
        }
    }

    @media small-query {
        .body {
            .values {
                grid-template-columns: 1fr;
                grid-template-areas: \
                    "para1"\
                    "para2"\
                    "para3";
            }
        }
    }
</style>
