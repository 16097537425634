<template>
    <div class="page-title">
        <div class="top"/>
        <div class="bottom"/>
        <div class="middle">
            <div class="title">{{ title }}</div>
            <div class="underline"/>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';

    export default Vue.extend({
        name: 'PageTitle',
        props: {
            title: {
                type: String,
                required: true
            }
        }
    });
</script>

<style scoped lang="stylus">
    @require '../variables.styl';

    .page-title {
        display: grid;
        grid-template-columns: 1fr minmax(min-page-width, max-page-width) 1fr;
        grid-template-areas: "top top top" \
            " . page ." \
            "bottom bottom bottom";
        background-color: footer-background;

        .top {
            grid-area: top;
            background-color: bold-color;
            height: 5px;
        }

        .middle {
            grid-column: 2;
            grid-row-start: 2;
            grid-row-end: 4;
            display: grid;
            grid-template-rows: 1fr 5px;
            grid-template-columns: auto 1fr;

            .title {
                title-font();
                color: title-color;
                grid-column: 1;
                grid-row: 1;
                font-size: 24px;
                padding: 20px 10px;
            }

            .underline {
                grid-column: 1;
                grid-row: 2;
                background-color: link-color;
                height: 5px;
            }
        }

        .bottom {
            grid-area: bottom;
            background-color: text-color;
            height: 5px;
        }
    }
</style>
